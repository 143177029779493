import React from 'react'

import cream_rolls_jagoda from '../images/products/cream-rolls-jagoda.jpg'
import cream_rolls_cokolada from '../images/products/cream-rolls-cokolada.jpg'
import cream_rolls_vanila from '../images/products/cream-rolls-vanila.jpg'
import cream_rolls_ljesnjak from '../images/products/cream-rolls-ljesnjak.jpg'
import cream_rolls_pomorandza from '../images/products/cream-rolls-pomorandza.jpg'

class CreamRolls extends React.Component{
    render(){
        return(
            <section id="proizvodi" className="hero products-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">
                            <div className="content">
                                <div className="title">CREAM ROLLS</div>
                                <span className="zigzag"></span>
                                <p className="text">Nova dimenzija ukusa smeštena je unutar Cream Rolls, rolnica koje 
                                šire pozitivnu energiju, okupljaju stare i povezuju nove potrošače. Tajna je u lepoti 
                                prvog zalogaja koja traje do ponovnog susreta. Srešćemo se opet (Do novog zalogaja).</p>
                            </div>
                        </div>{/*STATIC PART END */}
                        <div className="column is-8 content-part is-right padding-0">
                            <div className="columns is-multiline inner margin-0">
                                <div className="column is-6 single-product padding-0">
                                    <img src={cream_rolls_cokolada} alt="Cream rolls čokolada" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={cream_rolls_jagoda} alt="Cream rolls jagoda" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={cream_rolls_ljesnjak} alt="Cream rolls lješnjak" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={cream_rolls_pomorandza} alt="Cream rolls pomorandža" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={cream_rolls_vanila} alt="Cream rolls vanila" />
                                </div>
                            </div>
                        </div>{/* PRODUCTS PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default CreamRolls